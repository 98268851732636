<template>
  <MapPopup class="vehicle-popup">
    <template #title> Vehicle: {{ vehicleData.vehicle_id }} </template>
    <template #details>
      <div><b>Device ID: </b> {{ vehicleData.status.device_id }}</div>
      <div>
        <b>Trip: </b>
        <span v-if="vehicleData.status.trip.trip_id">{{ vehicleData.status.trip.trip_id }}</span>
        <span v-else>Unknown</span>
      </div>
      <div>
        <b>GTFS RT: </b>
        <span>{{ gtfsrtInfo }}</span>
      </div>
      <div>
        <b>Status: </b>
        <span>{{ deviceStatus }}</span>
      </div>
      <div>
        <b>Enabled: </b>
        <span>{{ vehicleData.config.is_enabled ? "Yes" : "No" }}</span>
      </div>
    </template>
    <template #footer>
      <v-btn color="primary" :to="{ name: 'next-stop-vehicle-config', params: { id: vehicleData.vehicle_id } }">
        Edit
      </v-btn>
    </template>
  </MapPopup>
</template>

<script>
import { mdiSquareEditOutline } from "@mdi/js"
import MapPopup from "./MapPopup.vue"

// import store from '../../../../store'

export default {
  name: "VehiclePopup",
  components: {
    MapPopup,
  },
  setup() {
    return {
      icons: {
        mdiSquareEditOutline,
      },
    }
  },
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      vehicle: {},
    }
  },
  computed: {
    gtfsrtInfo() {
      if (!this.vehicleData.rtData) {
        return "unavailable"
      }
      if (this.vehicleData.status.trip.trip_id === this.vehicleData.rtData?.trip.trip_id) {
        return "trip_id matches"
      }

      return "trip_id does not match"
    },
    deviceStatus() {
      if (!this.vehicleData.status.status) {
        return "Unknown"
      }
      if (this.vehicleData.status.status === "offline") {
        return "Offline"
      }

      return `Online (${this.vehicleData.status.status})`
    },
  },
  watch: {
    vehicleData(vehicle) {
      this.vehicle = vehicle
    },
  },
  methods: {
    // updateVolume(val) {
    //    store.dispatch('updateVehicle', val)
    // },
  },
}
</script>

<style lang="scss">
@import "~@core/preset/preset/variables.scss";

.mapboxgl-popup-content {
  border-radius: 10px;
}

.mapboxgl-popup-close-button {
  font-size: 20px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  top: 5px;
  right: 5px;
  background-color: white;
  color: var(--v-primary-base);

  &:hover {
    color: white;
    background-color: var(--v-primary-base);
  }
}
</style>
